import { default as _404aK5ZXnFtLvMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/404.vue?macro=true";
import { default as indexTf4Jeuii4rMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/analytics/index.vue?macro=true";
import { default as _91id_93sH2WB01ShbMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/[product]/[briefing]/[id].vue?macro=true";
import { default as createrXqfhm73FcMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/create.vue?macro=true";
import { default as indexrxyZebESEFMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/briefings/index.vue?macro=true";
import { default as _91_46_46_46slug_93SXTFfBKDJpMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/[...slug].vue?macro=true";
import { default as createlz2BXubK76Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/create.vue?macro=true";
import { default as indexs8Oq7AJW2wMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/index.vue?macro=true";
import { default as createg53bf8OlXwMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/create.vue?macro=true";
import { default as indexjVXJZDEqDwMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/index.vue?macro=true";
import { default as _91_46_46_46slug_932PBsLztlOhMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/[...slug].vue?macro=true";
import { default as createHFfNEWY8oSMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/create.vue?macro=true";
import { default as indexwnyDKF2TaVMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/index.vue?macro=true";
import { default as _91_46_46_46slug_932lAyN1acYmMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/[...slug].vue?macro=true";
import { default as create65TMd05tktMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/create.vue?macro=true";
import { default as index9u07utPeu5Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/products/index.vue?macro=true";
import { default as _91_46_46_46slug_93ML6Mh5wySOMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/[...slug].vue?macro=true";
import { default as createlUrqPnNUetMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/create.vue?macro=true";
import { default as indexc9YHqcJOMiMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/index.vue?macro=true";
import { default as _91_46_46_46slug_93OPQd2BtJoOMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/[...slug].vue?macro=true";
import { default as createBOMOQfiVrLMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/create.vue?macro=true";
import { default as indexAG9NmVUCdmMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/config/users/index.vue?macro=true";
import { default as indexyq8zV6p1uuMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/contact/index.vue?macro=true";
import { default as _91_46_46_46slug_93jJpFTS2WfXMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/demostraciones/[...slug].vue?macro=true";
import { default as indexmfqcKTdY6BMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/demostraciones/index.vue?macro=true";
import { default as graciasvRNWyYnj6mMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/gracias.vue?macro=true";
import { default as indexMP8I6EjQNbMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93xQnNiKqXdFMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/[...slug].vue?macro=true";
import { default as indexBAEZIuzuPgMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/activate/index.vue?macro=true";
import { default as createkEX3hEWwspMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/insights/create.vue?macro=true";
import { default as indexa2DBge7lIqMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/insights/index.vue?macro=true";
import { default as logincnETNez3NCMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/login.vue?macro=true";
import { default as _91_46_46_46slug_93hzdPIiIGwiMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/[...slug].vue?macro=true";
import { default as createWmJJ4UhEi9Meta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/create.vue?macro=true";
import { default as indexoNIlnIsOdSMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/plannings/index.vue?macro=true";
import { default as indexAqu6sUUHNRMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/pricing/index.vue?macro=true";
import { default as privacy5aXkPHeJFnMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/privacy.vue?macro=true";
import { default as _91_46_46_46slug_93WQ6uuLUEveMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/products/[...slug].vue?macro=true";
import { default as indexWtmDvk6ROHMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/products/index.vue?macro=true";
import { default as remember_45automaticvylq8aTpf9Meta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/remember-automatic.vue?macro=true";
import { default as rememberpxY6Sam2CYMeta } from "/Users/adria/FSBD/Projects-Hub/app-demo/pages/remember.vue?macro=true";
import { default as termsgcHdUAmUgoMeta } from "/Users/adria/FSBD/Projects-Hub/app/pages/terms.vue?macro=true";
export default [
  {
    name: "404",
    path: "/404",
    meta: _404aK5ZXnFtLvMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/404.vue").then(m => m.default || m)
  },
  {
    name: "Analytics",
    path: "/analytics",
    meta: indexTf4Jeuii4rMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/analytics/index.vue").then(m => m.default || m)
  },
  {
    name: "briefings-product-briefing-id",
    path: "/briefings/:product()/:briefing()/:id()",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/[product]/[briefing]/[id].vue").then(m => m.default || m)
  },
  {
    name: "briefings-create",
    path: "/briefings/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/create.vue").then(m => m.default || m)
  },
  {
    name: "briefings",
    path: "/briefings",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/briefings/index.vue").then(m => m.default || m)
  },
  {
    name: "config-companies-slug",
    path: "/config/companies/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-companies-create",
    path: "/config/companies/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/create.vue").then(m => m.default || m)
  },
  {
    name: "config-companies",
    path: "/config/companies",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/companies/index.vue").then(m => m.default || m)
  },
  {
    name: "config-invoices-create",
    path: "/config/invoices/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/create.vue").then(m => m.default || m)
  },
  {
    name: "config-invoices",
    path: "/config/invoices",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: "config-plans-slug",
    path: "/config/plans/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-plans-create",
    path: "/config/plans/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/create.vue").then(m => m.default || m)
  },
  {
    name: "config-plans",
    path: "/config/plans",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/plans/index.vue").then(m => m.default || m)
  },
  {
    name: "config-products-slug",
    path: "/config/products/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-products-create",
    path: "/config/products/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/create.vue").then(m => m.default || m)
  },
  {
    name: "config-products",
    path: "/config/products",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/products/index.vue").then(m => m.default || m)
  },
  {
    name: "config-signups-slug",
    path: "/config/signups/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-signups-create",
    path: "/config/signups/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/create.vue").then(m => m.default || m)
  },
  {
    name: "config-signups",
    path: "/config/signups",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/signups/index.vue").then(m => m.default || m)
  },
  {
    name: "config-users-slug",
    path: "/config/users/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "config-users-create",
    path: "/config/users/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/create.vue").then(m => m.default || m)
  },
  {
    name: "config-users",
    path: "/config/users",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/config/users/index.vue").then(m => m.default || m)
  },
  {
    name: "Contract",
    path: "/contact",
    meta: indexyq8zV6p1uuMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "DemosView",
    path: "/demostraciones/:slug(.*)*",
    meta: _91_46_46_46slug_93jJpFTS2WfXMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/demostraciones/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "Demos",
    path: "/demostraciones",
    meta: indexmfqcKTdY6BMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/demostraciones/index.vue").then(m => m.default || m)
  },
  {
    name: "gracias",
    path: "/gracias",
    meta: graciasvRNWyYnj6mMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/gracias.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "insights-slug",
    path: "/insights/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "insights-activate",
    path: "/insights/activate",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/activate/index.vue").then(m => m.default || m)
  },
  {
    name: "insights-create",
    path: "/insights/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/insights/create.vue").then(m => m.default || m)
  },
  {
    name: "Recomendaciones",
    path: "/insights",
    meta: indexa2DBge7lIqMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/insights/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: logincnETNez3NCMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "plannings-slug",
    path: "/plannings/:slug(.*)*",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "plannings-create",
    path: "/plannings/create",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/create.vue").then(m => m.default || m)
  },
  {
    name: "plannings",
    path: "/plannings",
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/plannings/index.vue").then(m => m.default || m)
  },
  {
    name: "Precios",
    path: "/pricing",
    meta: indexAqu6sUUHNRMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/pricing/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacy5aXkPHeJFnMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: "Visualización",
    path: "/products/:slug(.*)*",
    meta: _91_46_46_46slug_93WQ6uuLUEveMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/products/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "Productos",
    path: "/products",
    meta: indexWtmDvk6ROHMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "remember-automatic",
    path: "/remember-automatic",
    meta: remember_45automaticvylq8aTpf9Meta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/remember-automatic.vue").then(m => m.default || m)
  },
  {
    name: "remember",
    path: "/remember",
    meta: rememberpxY6Sam2CYMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app-demo/pages/remember.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsgcHdUAmUgoMeta || {},
    component: () => import("/Users/adria/FSBD/Projects-Hub/app/pages/terms.vue").then(m => m.default || m)
  }
]